<template>
  <div class="container-fluid">
    <div class="row mt-4">
      <label class="col-md-3" for="roomLocation">Location:</label>
      <input
        id="roomLocation"
        :disabled="room.preventEdit"
        v-model="room.location"
        maxlength="35"
        class="offset-md-1 col-md-7 form-control"
        type="text"
      />
    </div>
    <div class="row mt-4" v-if="!isCustomProp">
      <label for="roomGoals" class="col-md-3">Room Goals:</label>
      <input
        id="roomGoals"
        :disabled="room.preventEdit"
        v-model="room.roomGoals"
        class="offset-md-1 col-md-7 form-control"
        type="text"
      />
    </div>
    <div class="row mt-4">
      <h2 class="text-purple">1.) Identify Barriers</h2>
    </div>
    <div class="row mt-4 bg-light">
      <p class="col-lg-12 text-purple font-weight-bold">
        Select Personal Safety Score: (0 - Do Not Feel Safe through 10 - Feel
        Very Safe)
      </p>
    </div>
    <div class="row mt-2">
      <p class="col-lg-12">
        <small
          >Capture your client's perception of safety. For example, "Mrs. Jones,
          on a scale of 0-10, how safe do you feel in your ____room?"</small
        >
      </p>
    </div>
    <div class="row mt-4" v-if="!this.$route.params.roomId">
      <label class="col-sm-6 col-lg-3" :for="firstProperty.name">
        {{ firstProperty.name }}:
      </label>
      <select
        :disabled="room.preventEdit"
        :id="firstProperty.name"
        class="form-control col-sm-3"
        @change="setPropertyValue(firstProperty.name, true)"
      >
        <option value="n/a">N/A</option>
        <option value="0">0</option>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
        <option value="6">6</option>
        <option value="7">7</option>
        <option value="8">8</option>
        <option value="9">9</option>
        <option value="10">10</option>
      </select>
    </div>
    <div class="row mt-4" v-else>
      <label class="col-sm-6 col-lg-3" :for="firstProperty.propertyName">
        {{ firstProperty.propertyName }}:
        <span
          v-if="
            room.status == 'Follow-Up Assessment Started' ||
              room.status == 'Follow-Up Assessment Completed'
          "
          class="text-success"
          >({{ this.room.roomProperties[0].propertyValue }})</span
        >
      </label>
      <select
        :id="firstProperty.propertyName"
        :disabled="room.preventEdit"
        v-if="
          room.status == 'Initial Assessment Started' ||
            room.status == 'Initial Assessment Completed'
        "
        class="form-control col-sm-3"
        @change="setPropertyValue(firstProperty.propertyName, true)"
        v-model="firstProperty.propertyValue"
      >
        <option value="n/a">N/A</option>
        <option value="0">0</option>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
        <option value="6">6</option>
        <option value="7">7</option>
        <option value="8">8</option>
        <option value="9">9</option>
        <option value="10">10</option>
      </select>
      <select
        :id="firstProperty.propertyName"
        :disabled="room.preventEdit"
        v-else
        class="form-control col-sm-3"
        @change="setPostPropertyValue(firstProperty.propertyName, true)"
        v-model="firstProperty.propertyPostValue"
      >
        <option value="n/a">N/A</option>
        <option value="0">0</option>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
        <option value="6">6</option>
        <option value="7">7</option>
        <option value="8">8</option>
        <option value="9">9</option>
        <option value="10">10</option>
      </select>
    </div>
    <div class="row bg-light text-purple  mt-4">
      <div class="col-md-12  font-weight-bold">
        Identify Room Barriers: (
        <span style="color: red">0 - Not Accessible,</span>
        <span style="color: gold;">5 - Partial, </span>
        <span style="color:limegreen;">10 - Full Accessibility</span> )
      </div>
    </div>
    <div class="row">
      <p class="mt-2">
        <small
          >Determine areas of most risk by having your clients perform some or
          all of the activities important to them. If you cannot see the client,
          have them verbally walk through the activity. Score each activity
          appropriately.
        </small>
      </p>
    </div>
    <div class="row mt-4" v-if="!this.$route.params.roomId">
      <div
        v-for="property in filterOne"
        :key="property.id"
        class="col-sm-12 col-lg-6"
      >
        <div
          class="row mb-4 mt-4"
          v-if="
            (property.name !== 'Room Goals Rating' && isCustomProp) ||
              !isCustomProp
          "
        >
          <label :for="property.name + '-id'" class="col-sm-7"
            >{{ property.name }}:</label
          >
          <select
            :disabled="room.preventEdit"
            @change="setPropertyValue(property.name, false)"
            class="form-control col-md-3"
            :id="property.name + '-id'"
          >
            <option value="n/a">N/A</option>
            <option value="0">0</option>
            <option value="5">5</option>
            <option value="10">10</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row mt-4" v-else>
      <div
        v-for="property in filterOneExisting"
        :key="property.id"
        class="col-sm-12 col-lg-6"
      >
        <div
          class="row mb-4 mt-4"
          v-if="
            (property.name !== 'Room Goals Rating' && isCustomProp) ||
              !isCustomProp
          "
        >
          <label :for="property.definition + '-id'" class="col-sm-7"
            >{{ property.propertyName }}:
            <span
              v-if="
                room.status == 'Follow-Up Assessment Started' ||
                  room.status == 'Follow-Up Assessment Completed'
              "
              class="text-success"
              >({{ property.propertyValue }})</span
            ></label
          >
          <select
            :disabled="room.preventEdit"
            v-if="
              room.status == 'Initial Assessment Started' ||
                room.status == 'Initial Assessment Completed'
            "
            @change="setPropertyValue(property.name, false)"
            class="form-control col-md-3"
            v-model="property.propertyValue"
            :id="property.definition + '-id'"
          >
            <option value="n/a">N/A</option>
            <option value="0">0</option>
            <option value="5">5</option>
            <option value="10">10</option>
          </select>
          <select
            :disabled="room.preventEdit"
            v-else
            @change="setPostPropertyValue(property.name, false)"
            class="form-control col-sm-7"
            v-model="property.propertyPostValue"
            :id="property.definition + '-id'"
          >
            <option value="n/a">N/A</option>
            <option value="0">0</option>
            <option value="5">5</option>
            <option value="10">10</option>
          </select>
        </div>
      </div>
    </div>
    <RoomGoalsRow
      v-if="isCustomProp"
      :score-goals="scoreGoals"
      @score="setScore"
      :is-custom-room="isCustomProp"
      :disabled="room.preventEdit"
      @goals="setGoal"
      :list-goals="room.roomGoals"
      :show-text="
        room.status == 'Follow-Up Assessment Started' ||
          room.status == 'Follow-Up Assessment Completed'
      "
    />
    <div class="row mt-4">
      <div :class="this.$route.params.roomId ? 'col-xs-4' : 'col-xs-6'">
        <p class="text-center">
          <button
            @click="backToClientDetails()"
            class="btn purple-btn-outline font-weight-bold btn-shadow"
          >
            <FontAwesomeIcon icon="arrow-left" />&nbsp;&nbsp;Back
          </button>
        </p>
      </div>
      <div v-if="this.$route.params.roomId" class="col-xs-4">
        <p class="text-center">
          <button
            :disabled="room.preventEdit"
            @click="showModal = true"
            class="btn btn-danger btn-shadow font-weight-bold"
          >
            <FontAwesomeIcon icon="minus" />&nbsp;&nbsp;Delete
          </button>
        </p>
      </div>
      <div class="col-xs-4">
        <p class="text-center">
          <button
            :disabled="room.preventEdit"
            @click="submitForm()"
            class="btn btn-success btn-shadow font-weight-bold"
          >
            <FontAwesomeIcon icon="save" /> &nbsp;&nbsp;Save
          </button>
        </p>
      </div>
      <Modal v-model="showModal" title="Delete Room">
        <p>Are you sure you would like to delete this room?</p>
        <button @click="showModal = false" class="btn purple-btn btn-shadow">
          Back
        </button>
        <button
          @click="deleteRoom(room)"
          style="float:right;"
          class="btn btn-danger btn-shadow"
        >
          Delete
        </button>
      </Modal>
    </div>
    <div class="row mb-4 mt-4">
      <div class="col-md-12">
        <h2 class="text-purple">2.) Recommendations</h2>
        <p class="mt-2">
          <small
            >Select recommendations to improve safety and accessibility in the
            room.</small
          >
        </p>
      </div>
    </div>
    <div class="row bg-light mt-4">
      <div class="col-md-11">
        <button
          v-if="modificationDefinitions.length > 0"
          @click="tab = 'modifications'"
          v-bind:class="
            tab == 'modifications'
              ? 'btn btn-secondary'
              : 'btn btn-outline-secondary'
          "
          style="border:0 !important;"
        >
          Modifications
        </button>
        <button
          v-if="deluxeGrabBarDefinitions.length > 0"
          @click="tab = 'deluxe'"
          v-bind:class="
            tab == 'deluxe' ? 'btn btn-secondary' : 'btn btn-outline-secondary'
          "
          style="border:0 !important"
        >
          Deluxe Grab Bars
        </button>
        <button
          v-if="toiletModificationDefinitions.length > 0"
          @click="tab = 'toilet'"
          v-bind:class="
            tab == 'toilet' ? 'btn btn-secondary' : 'btn btn-outline-secondary'
          "
          style="border:0 !important"
        >
          Toilet Modifications
        </button>
        <button
          v-if="tubShowerModificationsDefinitions.length > 0"
          @click="tab = 'tub'"
          v-bind:class="
            tab == 'tub' ? 'btn btn-secondary' : 'btn btn-outline-secondary'
          "
          style="border:0 !important"
        >
          Tub Shower Modifications
        </button>
        <button
          v-if="vanityAreaDefinitions.length > 0"
          @click="tab = 'vanity'"
          v-bind:class="
            tab == 'vanity' ? 'btn btn-secondary' : 'btn btn-outline-secondary'
          "
          style="border: 0 !important"
        >
          Vanity Area
        </button>
        <button
          v-if="accessoriesDefinitions.length > 0"
          @click="tab = 'accessories'"
          v-bind:class="
            tab == 'accessories'
              ? 'btn btn-secondary'
              : 'btn btn-outline-secondary'
          "
          style="border:0 !important"
        >
          Accessories
        </button>
        <button
          v-if="otherDefinitions.length > 0"
          @click="tab = 'other'"
          v-bind:class="
            tab == 'other' ? 'btn btn-secondary' : 'btn btn-outline-secondary'
          "
          style="border:0 !important"
        >
          Other
        </button>
        <button
          v-if="assistiveTechDefinitions.length > 0"
          @click="tab = 'assistiveTech'"
          v-bind:class="
            tab == 'assistiveTech'
              ? 'btn btn-secondary'
              : 'btn btn-outline-secondary'
          "
          style="border:0 !important"
        >
          Assistive Tech
        </button>
        <button
          @click="tab = 'notes'"
          v-bind:class="
            tab == 'notes' ? 'btn btn-secondary' : 'btn btn-outline-secondary'
          "
          style="border:0 !important"
        >
          Notes
        </button>
      </div>
    </div>
    <div v-if="tab === 'modifications'">
      <div class="row mt-4">
        <div
          v-for="recommendation in modificationDefinitions"
          :key="recommendation.id"
          class=" col-sm-6 col-lg-4"
        >
          <div class="row mb-4 mt-4">
            <input
              :disabled="room.preventEdit"
              @click="addOrRemoveRecommendation(recommendation.name)"
              type="checkbox"
              :checked="getIfRecommendationChecked(recommendation.name)"
              :id="recommendation.name + '-recid'"
              class="mr-2 mt-1 ml-3"
            />
            <label :for="recommendation.name + '-recid'"
              >{{ recommendation.name }}&nbsp;&nbsp;<a
                target="_blank"
                v-if="recommendation.image"
                :href="recommendation.image"
              >
                <FontAwesomeIcon icon="info" /> </a
            ></label>
          </div>
        </div>
      </div>
    </div>
    <div v-if="tab === 'deluxe'">
      <div class="row mt-4">
        <div
          v-for="recommendation in deluxeGrabBarDefinitions"
          :key="recommendation.id"
          class="col-md-4"
        >
          <div class="row mb-4 mt-4">
            <input
              :disabled="room.preventEdit"
              @click="addOrRemoveRecommendation(recommendation.name)"
              type="checkbox"
              :checked="getIfRecommendationChecked(recommendation.name)"
              :id="recommendation.name + '-recid'"
              class="mr-2 mt-1 ml-3"
            />
            <label :for="recommendation.name + '-recid'"
              >{{ recommendation.name }}&nbsp;&nbsp;<a
                target="_blank"
                v-if="recommendation.image"
                :href="recommendation.image"
              >
                <FontAwesomeIcon icon="info" /> </a
            ></label>
          </div>
        </div>
      </div>
      <RoomGrabBarsSection v-if="this.$route.params.roomId" />
    </div>
    <div v-if="tab === 'toilet'">
      <div class="row mt-4">
        <div
          v-for="recommendation in toiletModificationDefinitions"
          :key="recommendation.id"
          class="col-md-4"
        >
          <div class="row mb-4 mt-4">
            <input
              :disabled="room.preventEdit"
              @click="addOrRemoveRecommendation(recommendation.name)"
              type="checkbox"
              :checked="getIfRecommendationChecked(recommendation.name)"
              :id="recommendation.name + '-recid'"
              class="mr-2 mt-1 ml-3"
            />
            <label :for="recommendation.name + '-recid'"
              >{{ recommendation.name }}&nbsp;&nbsp;<a
                target="_blank"
                v-if="recommendation.image"
                :href="recommendation.image"
              >
                <FontAwesomeIcon icon="info" /> </a
            ></label>
          </div>
        </div>
      </div>
    </div>
    <div v-if="tab === 'tub'">
      <div class="row mt-4">
        <div
          v-for="recommendation in tubShowerModificationsDefinitions"
          :key="recommendation.id"
          class="col-md-4"
        >
          <div class="row mb-4 mt-4">
            <input
              :disabled="room.preventEdit"
              @click="addOrRemoveRecommendation(recommendation.name)"
              type="checkbox"
              :checked="getIfRecommendationChecked(recommendation.name)"
              :id="recommendation.name + '-recid'"
              class="mr-2 mt-1 ml-3"
            />
            <label :for="recommendation.name + '-recid'"
              >{{ recommendation.name }}&nbsp;&nbsp;<a
                target="_blank"
                v-if="recommendation.image"
                :href="recommendation.image"
              >
                <FontAwesomeIcon icon="info" /> </a
            ></label>
          </div>
        </div>
      </div>
    </div>
    <div v-if="tab === 'vanity'">
      <div class="row mt-4">
        <div
          v-for="recommendation in vanityAreaDefinitions"
          :key="recommendation.id"
          class="col-md-4"
        >
          <div class="row mb-4 mt-4">
            <input
              :disabled="room.preventEdit"
              @click="addOrRemoveRecommendation(recommendation.name)"
              type="checkbox"
              :checked="getIfRecommendationChecked(recommendation.name)"
              :id="recommendation.name + '-recid'"
              class="mr-2 mt-1 ml-3"
            />
            <label :for="recommendation.name + '-recid'"
              >{{ recommendation.name }}&nbsp;&nbsp;<a
                target="_blank"
                v-if="recommendation.image"
                :href="recommendation.image"
              >
                <FontAwesomeIcon icon="info" /> </a
            ></label>
          </div>
        </div>
      </div>
    </div>
    <div v-if="tab === 'accessories'">
      <div class="row mt-4">
        <div
          v-for="recommendation in accessoriesDefinitions"
          :key="recommendation.id"
          class="col-md-4"
        >
          <div class="row mb-4 mt-4">
            <input
              :disabled="room.preventEdit"
              @click="addOrRemoveRecommendation(recommendation.name)"
              type="checkbox"
              :checked="getIfRecommendationChecked(recommendation.name)"
              :id="recommendation.name + '-recid'"
              class="mr-2 mt-1 ml-3"
            />
            <label :for="recommendation.name + '-recid'"
              >{{ recommendation.name }}&nbsp;&nbsp;<a
                target="_blank"
                v-if="recommendation.image"
                :href="recommendation.image"
              >
                <FontAwesomeIcon icon="info" /> </a
            ></label>
          </div>
        </div>
      </div>
    </div>
    <div v-if="tab === 'assistiveTech'">
      <div class="row mt-4">
        <div
          v-for="recommendation in assistiveTechDefinitions"
          :key="recommendation.id"
          class="col-md-4"
        >
          <div class="row mb-4 mt-4">
            <input
              :disabled="room.preventEdit"
              @click="addOrRemoveRecommendation(recommendation.name)"
              type="checkbox"
              :checked="getIfRecommendationChecked(recommendation.name)"
              :id="recommendation.name + '-recid'"
              class="mr-2 mt-1 ml-3"
            />
            <label :for="recommendation.name + '-recid'"
              >{{ recommendation.name }}&nbsp;&nbsp;<a
                target="_blank"
                v-if="recommendation.image"
                :href="recommendation.image"
              >
                <FontAwesomeIcon icon="info" /> </a
            ></label>
          </div>
        </div>
      </div>
    </div>
    <div v-if="tab === 'other'">
      <div class="row mt-4">
        <div
          v-for="recommendation in otherDefinitions"
          :key="recommendation.id"
          class="col-md-4"
        >
          <div class="row mb-4 mt-4">
            <input
              :disabled="room.preventEdit"
              @click="addOrRemoveRecommendation(recommendation.name)"
              type="checkbox"
              :checked="getIfRecommendationChecked(recommendation.name)"
              :id="recommendation.name + '-recid'"
              class="mr-2 mt-1 ml-3"
            />
            <label :for="recommendation.name + '-recid'"
              >{{ recommendation.name }}&nbsp;&nbsp;<a
                target="_blank"
                v-if="recommendation.image"
                :href="recommendation.image"
              >
                <FontAwesomeIcon icon="info" /> </a
            ></label>
          </div>
        </div>
      </div>
    </div>

    <div v-show="tab === 'notes'">
      <div class="row mt-4 ml-2">
        <div class="col-md-12">
          <label for="initialAssessmentNotes">Initial Assessment Notes:</label>
        </div>
      </div>
      <div class="row mb-4 ml-2">
        <div class="col-md-12">
          <RichTextEditor
            id="initialAssessmentNotes"
            :editorProp="editor1"
            :contentProp="room.preAssessmentNotes"
            :numberProp="1"
          />
        </div>
      </div>
      <div class="row ml-2">
        <div class="col-md-12">
          <label for="followUpAssessmentNotes"
            >Follow-Up Assessment Notes:</label
          >
        </div>
      </div>
      <div class="row mb-4 ml-2">
        <div class="col-md-12">
          <RichTextEditor
            id="followUpAssessmentNotes"
            :editorProp="editor2"
            :contentProp="room.postAssessmentNotes"
            :numberProp="2"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <h2 class="text-purple">3.) Photos</h2>
      </div>
    </div>
    <div class="row ml-2 mb-4 mt-4">
      <div v-if="!this.$route.params.roomId" class="col-md-12 mb-4">
        Save room information prior to uploading photos. Photo file sizes may
        not exceed <strong>10MB</strong> please shrink files before uploading.
      </div>
      <div v-else>
        <div>
          <p>Upload up to six photos to be included with this room.</p>
        </div>
        <div class="row ml-2 mt-4">
          <RoomImagesRow
            :preventEdit="room.preventEdit"
            :clientId="room.clientId"
            :roomId="room.id"
            @toggle-loading="toggleLoading"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { roomService, contentService } from "../../_services/index";
import { Editor } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
//import RoomImageUpload from '../../components/Rooms/RoomImageUpload'
import RoomImagesRow from "../../components/Rooms/RoomImagesRow";
import router from "../../router/index";
import VueModal from "@kouts/vue-modal";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import "@kouts/vue-modal/dist/vue-modal.css";
import RichTextEditor from "../../components/Shared/RichTextEditor.vue";
import RoomGrabBarsSection from "./RoomGrabBarsSection.vue";
import RoomGoalsRow from "./RoomGoalsRow.vue";
import Link from '@tiptap/extension-link'

const ROOM_TYPES = [
  "Bathroom",
  "Bedroom",
  "Dining Space",
  "Entryway",
  "Kitchen",
  "Living Room",
  "Hallway",
  "Outdoor Space",
  "Stairs",
  "Laundry Room",
];

export default {
  components: {
    Modal: VueModal,
    RoomImagesRow,
    FontAwesomeIcon,
    RichTextEditor,
    RoomGrabBarsSection,
    RoomGoalsRow,
  },
  data() {
    return {
      showModal: false,
      existingRooms: [],
      tab: "",
      room: {
        id: null,
        name: "",
        roomType: "",
        clientId: "",
        location: "",
        roomGoals: "",
        preAssessmentNotes: this.preAssessmentNotes ? this.preAssessmentNotes : "",
        postAssessmentNotes: this.postAssessmentNotes ? this.postAssessmentNotes : "",
        personalSafetyPreScore: null,
        personalSafetyPostScore: null,
        accessibilityPreScore: null,
        accessibilityPostScore: null,
        createdOn: null,
        updatedOn: null,
        status: "Initial Assessment Started",
        roomProperties: [],
        roomRecommendations: [],
        isCustom: false,
      },
      editor1: null,
      editor2: null,
      propertyDefinitions: [],
      recommendationDefinitions: [],
      modificationDefinitions: [],
      deluxeGrabBarDefinitions: [],
      deluxeGrabBarLocationDefinitions: [],
      toiletModificationDefinitions: [],
      tubShowerModificationsDefinitions: [],
      vanityAreaDefinitions: [],
      accessoriesDefinitions: [],
      assistiveTechDefinitions: [],
      otherDefinitions: [],
      firstProperty: {},
      isCustomProp: !ROOM_TYPES.includes(this.roomName),
    };
  },
  props: {
    roomProp: Object,
    safetyScore: String,
    locationDescription: String,
    roomGoals: String,
    roomName: String,
    preAssessmentNotes: String,
    postAssessmentNotes: String,
    clientProp: Object,
  },
  mounted() {
    // if room exists
      if (this.$route.params.roomId) {
        this.room = this.roomProp;
        if (this.clientProp) {
          this.room.status = this.clientProp.status;
        }
        this.firstProperty = this.room.roomProperties[0];
        var count = 0;
        var sum = 0;
        var scoreToEmit = 0;
        for (var i = 1; i < this.room.roomProperties.length; i++) {
          // check status here
          if (
            this.room.status == "Initial Assessment Started" ||
            this.room.status == "Initial Assessment Completed"
          ) {
            if (
              (this.room.roomProperties[i].propertyValue &&
                this.room.roomProperties[i].propertyValue != "n/a") ||
              this.room.roomProperties[i].propertyValue == "0"
            ) {
              count +=
                this.isCustomProp &&
                this.room.roomProperties[i].propertyName === "Room Goals Rating"
                  ? this.getCountValuesGoals(
                      this.room.roomProperties[i].propertyValue
                    )
                  : 1;
              sum += Number(
                !ROOM_TYPES.includes(this.room.roomType) &&
                  this.room.roomProperties[i].propertyName ===
                    "Room Goals Rating"
                  ? this.getSumValGoal(
                      this.room.roomProperties[i].propertyValue
                    )
                  : this.room.roomProperties[i].propertyValue
              );
            }
          } else {
            if (
              (this.room.roomProperties[i].propertyPostValue &&
                this.room.roomProperties[i].propertyPostValue != "n/a") ||
              this.room.roomProperties[i].propertyPostValue == "0"
            ) {
              count +=
                this.isCustomProp &&
                this.room.roomProperties[i].propertyName === "Room Goals Rating"
                  ? this.getCountValuesGoals(
                      this.room.roomProperties[i].propertyValue
                    )
                  : 1;
              sum += Number(
                !ROOM_TYPES.includes(this.room.roomType) &&
                  this.room.roomProperties[i].propertyName ===
                    "Room Goals Rating"
                  ? this.getSumValGoal(
                      this.room.roomProperties[i].propertyValue
                    )
                  : this.room.roomProperties[i].propertyValue
              );
            }
          }
        }

        scoreToEmit = (sum / count) * 10;

        this.$emit("calculate-accessibility-score", scoreToEmit);
        if (
          this.room.status != "Initial Assessment Started" &&
          this.room.status != "Initial Assessment Completed"
        ) {
          this.$emit("set-preassessment-accessibility", scoreToEmit);
          this.$emit(
            "calculate-safety-score",
            this.room.roomProperties[0].propertyPostValue
          );
        } else {
          if (
            !this.room.roomProperties[0].propertyValue &&
            this.room.roomProperties[0].propertyValue != "0"
          ) {
            this.$emit("calculate-safety-score", null);
          } else {
            this.$emit(
              "calculate-safety-score",
              Number(this.room.roomProperties[0].propertyValue)
            );
          }
        }
      }
      // if room is new create
      else {
        this.room.clientId = this.$route.params.id;
        this.room.roomType = this.roomName;
        this.room.isCustom = this.isCustomProp;
        this.$emit("set-loading", true);
        roomService
          .getRoomPropertyDefinitionsByRoomName(
            this.isCustomProp ? "Custom" : this.roomName
          )
          .then((response) => {
            this.firstProperty = response.data[i];
            for (var i = 0; i < response.data.length; i++) {
              var property = {};
              property.id = null;
              property.roomId = null;
              property.propertyName = response.data[i].name;
              property.propertyValue = null;
              property.propertyPostValue = null;
              this.room.roomProperties.push(property);
            }
            this.firstProperty = response.data[0];
            this.propertyDefinitions = response.data;
          });
        if (!this.isCustomProp) {
          this.room.roomProper;
        }
      }
   
    // TODO: Use state for this instead
    contentService.getRecommendationDefinitionsByRoomName(this.roomName).then(
      (response) => {
        this.$emit("set-loading", false);
        this.recommendationDefinitions = response;
        for (var i = 0; i < this.recommendationDefinitions.length; i++) {
          var category = this.recommendationDefinitions[i].category;
          if (category === "Modifications") {
            this.modificationDefinitions.push(
              this.recommendationDefinitions[i]
            );
          } else if (category === "Deluxe Grab Bars") {
            this.deluxeGrabBarDefinitions.push(
              this.recommendationDefinitions[i]
            );
          } else if (category === "Deluxe Grab Bars Location") {
            this.deluxeGrabBarLocationDefinitions.push(
              this.recommendationDefinitions[i]
            );
          } else if (category === "Toilet Modifications") {
            this.toiletModificationDefinitions.push(
              this.recommendationDefinitions[i]
            );
          } else if (category === "Tub Shower Modifications") {
            this.tubShowerModificationsDefinitions.push(
              this.recommendationDefinitions[i]
            );
          } else if (category === "Vanity Area") {
            this.vanityAreaDefinitions.push(this.recommendationDefinitions[i]);
          } else if (category === "Accessories") {
            this.accessoriesDefinitions.push(this.recommendationDefinitions[i]);
          } else if (category === "Other") {
            this.otherDefinitions.push(this.recommendationDefinitions[i]);
          } else if (category === "Assistive Tech") {
            this.assistiveTechDefinitions.push(
              this.recommendationDefinitions[i]
            );
          }
        }
        this.tab =
          this.modificationDefinitions.length > 0 ? "modifications" : "deluxe";
      },
      () => {
        //error
        this.$emit("set-loading", false);
      }
    );
    // new
    this.editor1 = new Editor({
      extensions: [
        StarterKit,
        Link.configure({ openOnClick: true })
      ],
      content: this.room.preAssessmentNotes
        ? this.room.preAssessmentNotes
        : " ",
    });
    this.editor2 = new Editor({
        extensions: [
          StarterKit,
          Link.configure({ openOnClick: true })
        ],
        content: this.room.postAssessmentNotes
        ? this.room.postAssessmentNotes
        : " ",
    });
  },
  computed: {
    filterOne: function() {
      const sliceItems = this.isCustomProp ? 2 : 1;
      return this.propertyDefinitions.slice(sliceItems);
    },
    filterOneExisting: function() {
      const changedRoomProperties = this.room.roomProperties;
      changedRoomProperties.forEach((item) => {
        if (item.propertyValue === null) {
          item.propertyValue = "n/a";
        }
      });
      const sliceItems = this.isCustomProp ? 2 : 1;
      return changedRoomProperties.slice(sliceItems);
    },
    scoreGoals() {
      return this.room.roomProperties.filter(
        (item) => item.propertyName === "Room Goals Rating"
      );
    },
  },
  methods: {
    getGoalsScoreValues(val) {
      const valuesArray = val.split(",").map((item) => item.slice(1, -1));
      const filteredValues = valuesArray.filter((item) => item !== "n/a");
      const numericValues = filteredValues.map((item) => parseFloat(item));
      return numericValues;
    },
    getCountValuesGoals(val) {
      const isFirstVal = val.includes(",");
      if (isFirstVal) {
        const count = this.getGoalsScoreValues(val).length;
        return count;
      } else {
        return 1;
      }
    },
    getSumValGoal(val) {
      const isFirstVal = val.includes(",");
      if (isFirstVal) {
        const sum = this.getGoalsScoreValues(val).reduce(
          (acc, obj) => acc + obj,
          0
        );
        return sum;
      } else {
        return val.slice(1, -1);
      }
    },
    setScore(val) {
      this.room.roomProperties[1].propertyValue = val;
      this.setPropertyValue("Room Goals Rating", false);
    },
    setGoal(val) {
      this.room.roomGoals = this.isCustomProp ? val : val.slice(1, -1);
    },
    emailProductNavigation: function() {
      window.location.href =
        "https://www.homeforlifedesign.com/#!professional-room-selection/c6or";
    },
    backToClientDetails() {
      router.push(`/clients/${this.$route.params.id}`);
    },
    toggleLoading(e) {
      if (e) {
        this.$emit("set-loading", true);
      } else {
        this.$emit("set-loading", false);
      }
    },
    addOrRemoveRecommendation(recommendationName) {
      if (event.target.checked) {
        var recommendationToAdd = {};
        recommendationToAdd.Id = null;
        recommendationToAdd.recommendationName = recommendationName;
        recommendationToAdd.recommendationCategory = "Modifications";
        if (!this.$route.params.roomId) {
          recommendationToAdd.roomId = null;
        } else {
          recommendationToAdd.roomId = this.$route.params.roomId;
        }
        this.room.roomRecommendations.push(recommendationToAdd);
      } else {
        for (var i = 0; i < this.room.roomRecommendations.length; i++) {
          if (
            this.room.roomRecommendations[i].recommendationName ==
            recommendationName
          ) {
            this.room.roomRecommendations.splice(i, 1);
          }
        }
      }
    },
    setPostPropertyValue(propertyName, isFirstProp) {
      console.log('setting post property value')
      var accessTotal = 0;
      var accessCount = 0;
      if (isFirstProp) {
        this.room.roomProperties[0].propertyPostValue = event.target.value;
        if (
          this.room.roomProperties[0].propertyPostValue ||
          this.room.roomProperties[0].propertyPostValue == "0"
        ) {
          this.$emit(
            "calculate-safety-score",
            Number(this.room.roomProperties[0].propertyPostValue)
          );
          this.room.personalSafetyPostScore = Number(
            this.room.roomProperties[0].propertyPostValue
          );
        } else {
          this.$emit("calculate-safety-score", 0);
        }
      } else {
        for (var i = 1; i < this.room.roomProperties.length; i++) {
          if (this.room.roomProperties[i].propertyName == propertyName) {
            if (event.target.value || event.target.value == 0) {
              this.room.roomProperties[i].propertyPostValue = String(
                event.target.value
              );
            }
          }
          if (
            this.room.roomProperties[i].propertyPostValue != "n/a" &&
            this.room.roomProperties[i].propertyPostValue != "" &&
            this.room.roomProperties[i].propertyPostValue != null
          ) {
            accessCount +=
              this.isCustomProp &&
              this.room.roomProperties[i].propertyName === "Room Goals Rating"
                ? this.getCountValuesGoals(
                    this.room.roomProperties[i].propertyPostValue
                  )
                : 1;
            accessTotal += Number(
              this.isCustomProp &&
                this.room.roomProperties[i].propertyName === "Room Goals Rating"
                ? this.getSumValGoal(this.room.roomProperties[i].propertyPostValue)
                : this.room.roomProperties[i].propertyPostValue
            );
          }
          var postEmit = accessTotal / accessCount;
          this.$emit("calculate-accessibility-score", postEmit * 10);
          this.room.accessibilityPostScore = postEmit * 10;
        }
      }
    },
    setPropertyValue(propertyName, isFirstProp) {
      var accessTotal = 0;
      var accessCount = 0;
      // this part is fine
      if (isFirstProp) {
        this.room.roomProperties[0].propertyValue = event.target.value;
        if (
          this.room.roomProperties[0].propertyValue ||
          this.room.roomProperties[0].propertyValue === "0"
        ) {
          this.$emit(
            "calculate-safety-score",
            Number(this.room.roomProperties[0].propertyValue)
          );
          this.room.personalSafetyPreScore = Number(
            this.room.roomProperties[0].propertyValue
          );
        } else {
          this.$emit("calculate-safety-score", 0);
        }
      } else {
        for (var i = 1; i < this.room.roomProperties.length; i++) {
          if (this.room.roomProperties[i].propertyName == propertyName) {
            if (event.target.value || event.target.value === 0) {
              if (
                !this.isCustomProp ||
                (this.isCustomProp && propertyName !== "Room Goals Rating")
              ) {
                this.room.roomProperties[i].propertyValue = String(
                  event.target.value
                );
              }
            }
          }
          if (
            this.room.roomProperties[i].propertyValue != "n/a" &&
            this.room.roomProperties[i].propertyValue != "" &&
            this.room.roomProperties[i].propertyValue != null
          ) {
            accessCount +=
              this.isCustomProp &&
              this.room.roomProperties[i].propertyName === "Room Goals Rating"
                ? this.getCountValuesGoals(
                    this.room.roomProperties[i].propertyValue
                  )
                : 1;
            accessTotal += Number(
              this.isCustomProp &&
                this.room.roomProperties[i].propertyName === "Room Goals Rating"
                ? this.getSumValGoal(this.room.roomProperties[i].propertyValue)
                : this.room.roomProperties[i].propertyValue
            );
          }
        }
        var numberToEmit = accessTotal / accessCount;
        this.$emit("calculate-accessibility-score", numberToEmit * 10);
        this.room.accessibilityPreScore = numberToEmit * 10;
      }
    },
    deleteRoom(room) {
      this.$emit("set-loading", true);
      roomService.deleteRoom(room.id, room.clientId).then(
        () => {
          this.$emit("set-loading", false);
          this.$toast.success("Room Deleted Successfully!", {
            position: "bottom-center",
            timeout: 5000,
            hideProgressBar: true,
          });
          router.push(`/clients/${room.clientId}`);
        },
        () => {
          this.$emit("set-loading", false);
          this.$toast.error("Failed to delete room.", {
            position: "bottom-center",
            timeout: 5000,
            hideProgressBar: true,
          });
        }
      );
    },
    submitForm() {
      this.room.clientId = Number(this.room.clientId);
      this.room.preAssessmentNotes = document.getElementById(
        "rich-editor"
      ).innerHTML;
      this.room.postAssessmentNotes = document.getElementById(
        "rich-editor2"
      ).innerHTML;
      this.$emit("set-loading", true);
      // new room
      if (!this.$route.params.roomId) {
        this.$emit("is-loading", true);
        localStorage.setItem("firstRoomLoad", true);
        roomService.createRoom(this.room).then(
          (response) => {
            this.$emit("set-loading", false);
            this.$router.push(`/clients/${response.data.clientId}`);
            this.$toast.success("Room Created Successfully!", {
              position: "bottom-center",
              timeout: 5000,
              hideProgressBar: true,
            });
          },
          () => {
            this.$emit("set-loading", false);
            this.$toast.error("Failed to create room.", {
              position: "bottom-center",
              timeout: 5000,
              hideProgressBar: true,
            });
          }
        );
      } else {
        roomService.editRoom(this.room, this.room.id).then(
          () => {
            this.$emit("set-loading", false);
            this.$toast.success("Changes Saved Successfully!", {
              position: "bottom-center",
              timeout: 5000,
              hideProgressBar: true,
            });
          },
          () => {
            this.$emit("set-loading", false);
            this.$toast.error("Failed to create room.", {
              position: "bottom-center",
              timeout: 5000,
              hideProgressBar: true,
            });
          }
        );
      }
    },
    getIfRecommendationChecked(recommendationName) {
      for (var i = 0; i < this.room.roomRecommendations.length; i++) {
        if (
          this.room.roomRecommendations[i].recommendationName ==
          recommendationName
        )
          return true;
      }
      return false;
    },
  },
};
</script>

<style></style>
